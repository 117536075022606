import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import htmldecoder from "../../../../functions/htmldecoder";

import { I18nContext } from "../../../../i18n/index";
import "./Styles/OtherInfoTab.css";


const OtherInfoTab = ({ longDesc, properties }) => {
  const { translate } = React.useContext(I18nContext);

  
  return (
    <div
      className="product-details-specs-container"
      style={{ backgroundColor: "#f9f9f9" }}
    >
     

     
           <div id="Specifi">
            {properties && properties.length > 0 ? (
              <div className="specs-list">

                {/* <table className="table table-striped specCategory">
                  <tbody>
                    {properties
                      ? properties
                          .slice(properties.length / 2, properties.length)
                          .map(property => {
                            return (
                              <tr key={property.propname} className={`cat_${property.propname.split("_").join(" ")}`}>
                                <th>
                                    {property.propname.split("_").join(" ")}
                                </th>
                                <td>{property.propvalue.replace('Https://Www.Alibaba.Com/Product-Detail/High-Quality-With-Reasonable-Price-Palm_60239497506.Html','link')}</td>
                              </tr>
                            );
                          })
                      : null}
                  </tbody>
                </table> */}

                {/* <table className="table table-striped Packaging">
                  <tbody>
                    {properties
                      ? properties
                          .slice(0, properties.length / 2)

                          .map(property => {
                            return (
                              <tr key={property.propname} className={`spec_${property.propname.split("_").join(" ")}`}>
                                <th>
                                    {property.propname.split("_").join(" ")}
                                </th>
                                <td>{property.propvalue}</td>
                              </tr>
                            );
                          })
                      : null}
                  </tbody>
                </table> */}

                
              </div>
            ) : (
              <h3>There is no {translate("js.item.specifications")} to show</h3>
            )}
          </div>
      

    </div>
  );
};

export default OtherInfoTab;
