import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import htmldecoder from "../../../../functions/htmldecoder";

import { I18nContext } from "../../../../i18n/index";
import "./Styles/OtherInfoTab.css";



const OtherInfoTab = ({ longDesc, properties }) => {
  const { translate } = React.useContext(I18nContext);




  return (
    <div
      className="product-details-specs-container"
      style={{ backgroundColor: "white" }}
    >


      {properties && properties.length > 0 ? (
        <div className="specs-list">

          <h1 className="pal-head">Specifications</h1>

          <table className="table table-striped specCategory">
            <tbody>
              {properties
                ? properties
                  .slice(properties.length / 2, properties.length)
                  .map(property => {
                    return (
                      <tr key={property.propname} className={`cat_${property.propname.split("_").join(" ")}`}>
                        <th>
                          {property.propname.replace('Specifications', 'Description').replace('Description URL', 'Quality Certificates').split("_").join(" ")}
                        </th>

                        {property.propname == "Specifications_URL" ?
                          <>
                            <td>
                              <a title={property.propvalue} target="_blank" href={property.propvalue}>
                                {property.propvalue}
                              </a>
                            </td>
                          </> :

                          <td title={property.propvalue}>
                            {property.propvalue}
                          </td>
                        }

                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
          {/* <table className="table table-striped Packaging">
            <tbody>
              {properties
                ? properties
                  .slice(0, properties.length / 2)

                  .map(property => {
                    return (
                      <tr key={property.propname} className={`specf_${property.propname.split("_").join(" ")}`}>
                        <th>
                          {property.propname.split("_").join(" ")}
                        </th>
                        <td>{property.propvalue.replace('https://www.google.com/url?sa=i&url=http%3A%2F%2Fwww.jayatiasa.net%2Fusr%2Fpagesub.aspx%3Fpgid%3D155&psig=AOvVaw1eIC6RnnVhaX0vntmXJS8Z&ust=1629395887802000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCOiyju6Su_ICFQAAAAAdAAAAABAJ', 'link')}</td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table> */}

        </div>
      ) : (
        <h3>There is no {translate("js.item.specifications")} to show</h3>
      )}



    </div>
  );
};

export default OtherInfoTab;
