// extracted by mini-css-extract-plugin
export var container = "SupplierInfo-module--container--GaHR2";
export var wrapper_main = "SupplierInfo-module--wrapper_main--rIN1L";
export var row = "SupplierInfo-module--row--B8DuB";
export var prop = "SupplierInfo-module--prop--Xjxfg";
export var storin = "SupplierInfo-module--storin--xC3EF";
export var value = "SupplierInfo-module--value--8l69K";
export var supplier_wrapper = "SupplierInfo-module--supplier_wrapper--Rgv1i";
export var supplier_inner_1 = "SupplierInfo-module--supplier_inner_1--fpkVX";
export var supplier_inner_2 = "SupplierInfo-module--supplier_inner_2--6Waeh";
export var sup_logo = "SupplierInfo-module--sup_logo--PtA2v";
export var sup_add = "SupplierInfo-module--sup_add--Hjn3E";
export var sup_contact = "SupplierInfo-module--sup_contact--q7yly";
export var sup_icons = "SupplierInfo-module--sup_icons--Iggkm";
export var more_details = "SupplierInfo-module--more_details--K+idD";
export var com_title = "SupplierInfo-module--com_title--+9oaL";
export var video = "SupplierInfo-module--video--VO8JC";