import React, { useContext, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { showChatPopup } from "../../../../redux/actions/chatActions";
import { I18nContext } from "../../../../i18n";
import Login from "../../../AC-LoginBox/Login";
import { useMutation, useQuery } from "@apollo/client";
import { INIT_CHAT } from "../Chat/mutations";
import {
  GET_CHAT,
  GET_RECEIVER_ID,
  GET_THE_CURRENT_USER
} from "../Chat/queries";
import Messenger from "../../../../assets/img/banners/message.svg";
import LoginProfileModal from "../../../../components/AC-Header/LoginProfileModal";

export default function ChatTheSeller({ storeSellerData }) {
  const login = useSelector(state => state.loginReducer, shallowEqual);
  const itemDetails = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );
  const vendorId = itemDetails?.hiddenProperties?.find(
    el => el.propname === "Created_By_Supplier"
  )?.propvalue;
  const { data: chatData } = useQuery(GET_CHAT);

  const [initChat] = useMutation(INIT_CHAT, {
    refetchQueries: [
      { query: GET_THE_CURRENT_USER, variables: { email: login.loginName } }
    ]
  });
  const { data } = useQuery(GET_RECEIVER_ID, {
    variables: {
      vendorId
    },
    skip: typeof vendorId === "undefined"
  });
  const securityToken = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );
  const dispatch = useDispatch();

  const { translate } = useContext(I18nContext);

  const [modal, setModal] = useState({ open: false, side: "right" });

  const handleEnquire = async event => {
    event.stopPropagation();
    if (securityToken) {
      // check if user is already in the system
      // if user exists
      // TODO: check if there is an existing chat between user-supplier
      if (
        !chatData?.chat?.some(chat =>
          chat.chat_users.some(user => user.user.id === data.users[0]?.id)
        )
      ) {
        initChat({
          variables: {
            userName: login.loginName,
            email: login.loginName,
            firstName: login.firstName,
            lastName: login.lastName,
            reciever_id: data?.users?.[0]?.id
          }
        });
      }
      dispatch(showChatPopup(vendorId));
    } else setModal({ open: true, side: "right" });
  };

  if (storeSellerData)
    return (
      <div className="contact-seller-section">
        <div className="shareprops" onClick={handleEnquire}>
          <a href="javascript:void(0)">
            <div className="sharepropsdiva">
              <img src={Messenger} />
            </div>{" "}
            Chat With Seller
          </a>
        </div>

        {/* <Drawer
          className="loginState"
          anchor="right"
          open={isEnquired}
          onClose={() => setIsEnquired(false)}
        >
          <div style={{ backgroundColor: "#fff", boxShadow: "0 0 5px #cdcdcd" }}>
            <h6 className="minicart-title CustomerLogin">
              {translate("CustomerLogin")}
              <i
                onClick={() => setIsEnquired(false)}
                className="material-icons"
                style={{ cursor: "pointer", fontSize: "30px" }}
              >
                close
              </i>
            </h6>
          </div>
          <div role="presentation">
            <Login />
          </div>
        </Drawer> */}
        <LoginProfileModal modalState={modal} />
      </div>
    );
  else return null;
}
