import React, { useEffect, useRef, useState, useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  getDeliveryOptions,
  addToLocalMiniCart,
  setAddToCartValidationErrors,
  setProductOutOfStockError,
  addToCartCheckboxProduct
} from "../../../../redux/actions/productAction";
import { I18nContext } from "../../../../i18n/index";
import { FormattedNumber } from "react-intl";
//import NotificationRequest from "../../Components/NotificationRequest/NotificationRequest"
import Loading from "../../../AC-Loading/Loading";
import GrandTotalCheckBoxItems from "./Components/GrandTotalCheckBoxItems";
import NotifyOnReStock from "../../../AC-Notifications/NotifyOnReStock";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import "./Styles/AddToCartBox.css";
import WishListBarMobile from "../WishList/WishListBarMobile";

import ChatTheSeller from "../ChatTheSeller/ChatTheSeller";
//import { fas } from "@fortawesome/free-solid-svg-icons";

const WIGGLE_TIMER = 2500;

const AddToCartBox = props => {
  const dispatch = useDispatch();
  const { translate, currency, priceConvert } = useContext(I18nContext);

  /* const [listPrice, setListPrice] = useState(
    props.priceInv.prices[0].listprice || 0
  ); */

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [firstDistId, setFirstDistId] = useState(0);
  const [price, setPrice] = useState(0);
  const [numberOfItems, setNumberOfItems] = useState(1);
  const [shouldWiggle, setShouldWiggle] = useState(false);
  const [perUnitState, setPerUnitState] = useState("");
  const [inStock, setInStock] = useState({
    initial: true,
    nextshipdate: "",
    discontinued: false,
    instock: false,
    hide: false,
    qty: ""
  });
  const initialValue = useRef(true);

  useEffect(() => {
    if (
      props &&
      props.priceInv &&
      props.priceInv.prices.length > 0 &&
      Object.keys(props.priceInv.prices[0]).includes("price_1")
    ) {
      setPrice(props.priceInv.prices[0].price_1);
    }
  }, [props]);

  console.info("addtocart props", props);

  console.info(
    "addtocart stock",
    inStock,
    !inStock.instock && inStock.discontinued && !inStock.nextshipdate
  );

  const checkBoxItemsState = useSelector(
    state => state.productReducer.checkboxItems,
    shallowEqual
  );

  const selectedCheckBoxItemsState = useSelector(
    state => state.productReducer.selectedCheckboxItems,
    shallowEqual
  );

  const supplierInfoState = useSelector(
    state => state.productReducer.supplierInfo,
    shallowEqual
  );

  const priceState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  const isProductDetailsLoading = useSelector(
    state => state.productReducer.loading,
    shallowEqual
  );

  const attributesState = useSelector(
    state => state.productReducer.itemDetail.attributes,
    shallowEqual
  );

  const attributeid =
    attributesState && attributesState[0] && attributesState[0].attributeid;

  const productAttributeCheckboxFlagState = useSelector(
    state => state.productReducer.productAttributeCheckboxFlag,
    shallowEqual
  );

  const selectedProductCheckboxAttributesState = useSelector(
    state => state.productReducer.selectedProductCheckboxAttributes,
    shallowEqual
  );

  const requestingAddToCartState = useSelector(
    state => state.productReducer.requestingAddToCart,
    shallowEqual
  );

  const mainItemIdState = useSelector(
    state => state.productReducer.itemDetail.mainitemid,
    shallowEqual
  );

  const skusState = useSelector(
    state => state.productReducer.itemDetail.skus,
    shallowEqual
  );

  const itemIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const selectedProductAttributesState = useSelector(
    state => state.productReducer.selectedProductAttributes,
    shallowEqual
  );

  const attributeDetailsState = useSelector(
    state => state.productReducer.itemDetail.attributeDetails,
    shallowEqual
  );

  const productInitialProperties = useSelector(
    state => state.productReducer.productInitial.properties,
    shallowEqual
  );

  useEffect(() => {
    if (isProductDetailsLoading) {
      setPerUnitState(null);
    } else {
      if (productInitialProperties && productInitialProperties.length > 0) {
        let perUnitProp = productInitialProperties.find(prop =>
          ["Per Unit", "Per-Unit"].includes(Object.keys(prop)[0])
        );
        console.info("perUnitProp", productInitialProperties, perUnitProp);
        if (perUnitProp) setPerUnitState(Object.values(perUnitProp)[0]);
      }
    }
  }, [isProductDetailsLoading, productInitialProperties]);

  useEffect(() => {
    if (
      priceState &&
      Object.keys(priceState).length > 0 &&
      supplierInfoState &&
      supplierInfoState[0] &&
      supplierInfoState[0].distributorOrder
    ) {
      const firstDistId = supplierInfoState?.[0]?.distributorOrder?.[0]?.distid;
      setFirstDistId(firstDistId);
      setPrice(
        priceState.prices.find(inv => inv.distributorId == firstDistId) &&
        priceState.prices.find(inv => inv.distributorId == firstDistId)
          .price_1
      );

      // determine stock status

      const instockQty =
        priceState.invs && priceState.invs[0] && priceState.invs[0].instock;

      const hidden =
        priceState.invs && priceState.invs[0] && priceState.invs[0].hide;

      const discontinued =
        priceState.invs &&
        priceState.invs[0] &&
        priceState.invs[0].discontinued;

      const nextshipdate =
        priceState.invs &&
        priceState.invs[0] &&
        priceState.invs[0].nextshipdate;

      setInStock({
        initial: false,
        nextshipdate,
        discontinued,
        instock: instockQty > 0,
        hide: hidden,
        qty: instockQty
      });
    }
  }, [priceState, supplierInfoState]);

  useEffect(() => {
    let timer = null;
    if (numberOfItems) {
      if (initialValue.current) {
        initialValue.current = false;
        return;
      }

      timer = setTimeout(() => {
        setShouldWiggle(true);
      }, WIGGLE_TIMER);
    }
    return () => {
      setShouldWiggle(false);
      clearTimeout(timer);
    };
  }, [numberOfItems]);

  const handleAddToCart = (quoteMode = false) => {
    if (!inStock.instock && inStock.discontinued) {
      dispatch(setProductOutOfStockError(true));
      return;
    }
    let attributesObject = null;

    let requiredFields = [];

    if (productAttributeCheckboxFlagState) {
      let expectedAttributeIdAndScreenName = "";
      if (attributeDetailsState && attributeDetailsState.length > 0) {
        expectedAttributeIdAndScreenName = attributeDetailsState[0];
      }

      let hasSelectedAttribute =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState[
        expectedAttributeIdAndScreenName.attributeid
        ] &&
        selectedProductCheckboxAttributesState[
          expectedAttributeIdAndScreenName.attributeid
        ].length > 0;

      if (!hasSelectedAttribute)
        requiredFields.push(expectedAttributeIdAndScreenName.screenname);
    } else {
      if (
        selectedProductAttributesState.hasOwnProperty(
          mainItemIdState || itemIdState
        )
      ) {
        attributesObject =
          selectedProductAttributesState[mainItemIdState || itemIdState];

        let expectedAttributes = attributeDetailsState.reduce((p, c) => {
          const { screenname, attributeid } = c;
          p = [...p, { screenname, attributeid }];
          return p;
        }, []);

        let receivedAttributeIds = Object.keys(attributesObject);

        console.info(
          "addToCartTest",
          attributesObject,
          expectedAttributes,
          receivedAttributeIds
        );

        if (mainItemIdState != 0) {
          expectedAttributes.forEach(attr => {
            if (!receivedAttributeIds.includes(attr.attributeid.toString()))
              requiredFields.push(attr.screenname);
          });
        } else {
          expectedAttributes.forEach(attr => {
            requiredFields.push(attr.screenname);
          });
        }
      } else {
        if (attributeDetailsState && attributeDetailsState.length > 0) {
          let expectedAttributes = attributeDetailsState.reduce((p, c) => {
            const { screenname, attributeid } = c;
            p = [...p, { screenname, attributeid }];
            return p;
          }, []);

          expectedAttributes.forEach(attr => {
            requiredFields.push(attr.screenname);
          });
        }
      }
    }

    dispatch(setAddToCartValidationErrors(requiredFields));

    if (requiredFields.length > 0) {
      return;
    }
    let firstDist = supplierInfoState[0].distributorOrder[0];

    let vid = firstDist.supplier_store_vid;

    if (
      productAttributeCheckboxFlagState &&
      selectedCheckBoxItemsState &&
      selectedCheckBoxItemsState.length === 0
    ) {
      let itemCount =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState[attributeid] &&
        selectedProductCheckboxAttributesState[attributeid].length;

      let priceInventory =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState.priceInventory;
      console.info("borop3", selectedProductAttributesState);
      let products = [];

      let keys = Object.keys(priceInventory).filter(key =>
        selectedProductCheckboxAttributesState[attributeid].includes(
          Number(key)
        )
      );

      let outOfStockAll = true;
      for (let key of keys) {
        let product = {};
        product.attributes = [attributeid, key];
        product.optionId = key;
        product.id = priceInventory[key].itemid;
        product.distributorId = priceInventory[key].invs[0].distributorId;
        product.qty = priceInventory[key].qty;

        //if out of stock don't add to the list of products and reduce the number of selected items
        if (
          priceInventory[key].invs[0].instock ||
          inStock.discontinued === false
        ) {
          products.push(product);
          outOfStockAll = false;
        } else {
          itemCount -= 1;
        }
      }

      if (outOfStockAll && inStock.discontinued) {
        dispatch(setProductOutOfStockError(true));
        return;
      }

      /*  let attributesObject = {};

      attributesObject = selectedProductCheckboxAttributesState[
        attributeid
      ].map(optionid => {
        return [attributeid, optionid];
      }); */

      dispatch(
        addToCartCheckboxProduct(
          itemIdState,
          itemCount,
          products,
          0,
          false,
          vid
        )
      );
    } else if (
      productAttributeCheckboxFlagState &&
      selectedCheckBoxItemsState &&
      selectedCheckBoxItemsState.length > 0
    ) {
      let itemCount =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState[attributeid] &&
        selectedProductCheckboxAttributesState[attributeid].length;

      itemCount += selectedCheckBoxItemsState.length;

      let priceInventory =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState.priceInventory;
      console.info("borop3", selectedProductAttributesState);
      let products = [];

      let keys = Object.keys(priceInventory).filter(key =>
        selectedProductCheckboxAttributesState[attributeid].includes(
          Number(key)
        )
      );

      let outOfStockAll = true;
      for (let key of keys) {
        let product = {};
        product.attributes = [attributeid, key];
        product.optionId = key;
        product.id = priceInventory[key].itemid;
        product.distributorId = priceInventory[key].invs[0].distributorId;
        product.qty = priceInventory[key].qty;

        //if out of stock don't add to the list of products and reduce the number of selected items
        if (priceInventory[key].invs[0].instock) {
          products.push(product);
          outOfStockAll = false;
        } else {
          itemCount -= 1;
        }
      }

      // add the extras to the products
      selectedCheckBoxItemsState.forEach(item => {
        let product = {};
        product.id = item.id;
        product.distributorId = item.distId;
        product.qty = item.qty;
        products.push(product);
      });

      if (outOfStockAll) {
        dispatch(setProductOutOfStockError(true));
        return;
      }

      /*  let attributesObject = {};

      attributesObject = selectedProductCheckboxAttributesState[
        attributeid
      ].map(optionid => {
        return [attributeid, optionid];
      }); */

      dispatch(
        addToCartCheckboxProduct(
          itemIdState,
          itemCount,
          products,
          0,
          false,
          vid
        )
      );
    } else if (
      selectedCheckBoxItemsState &&
      selectedCheckBoxItemsState.length > 0
    ) {
      let itemCount = 1 + selectedCheckBoxItemsState.length;
      let products = [];

      // add the actual product to the list of products
      let productItself = {
        distributorId: priceState.prices.find(
          inv => inv.distributorId == firstDistId
        ).distributorId,
        id: priceState.itemid,
        qty: 1
      };

      products.push(productItself);

      selectedCheckBoxItemsState.forEach(item => {
        let product = {};
        product.id = item.id;
        product.distributorId = item.distId;
        product.qty = item.qty;
        products.push(product);
      });

      dispatch(
        addToCartCheckboxProduct(
          itemIdState,
          itemCount,
          products,
          0,
          attributesObject,
          false,
          vid
        )
      );
    } else {
      console.info("firstDist", firstDist, vid);

      dispatch(
        getDeliveryOptions(
          priceState.prices.find(inv => inv.distributorId == firstDistId)
            .distributorId,
          priceState.code,
          numberOfItems,
          priceState.itemid,
          attributesObject,
          quoteMode,
          vid
        )
      );
    }
  };

  const handleSetQuantityInput = e => {
    const value = Number(e.target.value);
    console.info("value", value);
    if (value === 0) setNumberOfItems("");
    else if (value > 0 && String(value).length <= 9)
      setNumberOfItems(Number(e.target.value));
  };

  const handleOnInputBlur = e => {
    const value = e.target.value;
    console.info("value2", value);

    if (value === "") setNumberOfItems(1);
  };


  const renderPerUnitText = () => {
    if (perUnitState) {
      return (
        <strong className="add-to-cart-box--per-unit-text">
          {perUnitState}
        </strong>
      );
    } else return null;
  };

  return (
    <React.Fragment>
      <div id="addToCardGrid">
        <div id="pickupShipInfo"></div>
        <div className="buyBox">
          {inStock.initial ? (
            <Loading className="add-to-cart-box--price-loading" />
          ) : (
            <React.Fragment>
              <div className="buy-box-qty-price-container">
                {/* <div className="inStock-container">
                  {inStock.instock ? (
                    <div className="inStock-wrapper">
                      <label
                        className={
                          inStock.hide && inStock.qty < 6 ? "hiddenStock" : ""
                        }
                      >
                        {translate("js.item.stock")}
                      </label>
                      {inStock.hide === false ? (
                        inStock.qty < 6 ? (
                          <span
                            style={{
                              display: "flex",
                              width: "100%",
                              fontWeight: "600",
                              color: "#ca0a0a"
                            }}
                          >
                            Only {inStock.qty} left
                          </span>
                        ) : (
                          <span style={{ fontWeight: "600" }}>
                            :&nbsp;{inStock.qty}
                          </span>
                        )
                      ) : null}
                    </div>
                  ) : null}
                </div> */}

                <div
                  /*   style={{
                  visibility: isProductDetailsLoading ? "hidden" : "visible"
                }} */
                  className="buy-box-price-wrapper"
                >
                  <div id="buyBoxPrice">
                    {isProductDetailsLoading ? (
                      <Loading className="add-to-cart-box--price-loading" />
                    ) : (
                      <>
                        <FormattedNumber
                          value={price / priceConvert}
                          style="currency"
                          currency={currency}
                        />
                        <span style={{marginLeft:"10px",fontSize:"15px"}}>Insclusive of all taxes</span>
                      </>

                    )}

                    {/*<span className="symbol">
                      {currencyState.sign && currencyState.sign}
                    </span>
                    <span className="dollars">{price}</span>
                  {currencyState.code && currencyState.code}*/}
                  </div>
                  {/* <NotificationRequest /> */}
                  {renderPerUnitText()}
                </div>
                <div
                  className="buy-box-qty-wrapper"
                  style={{
                    display:
                      productAttributeCheckboxFlagState ||
                        (checkBoxItemsState && checkBoxItemsState.length > 0)
                        ? "none"
                        : ""
                  }}
                >
                  <div id="buyBoxQtyBox">
                    <div className="qtyControlsBox">
                      <div className="qtyControlsBtns">
                        <div
                          className="qtyControlsMinus no-select"
                          onClick={() => {
                            if (numberOfItems - 1 > 0) {
                              setNumberOfItems(numberOfItems - 1);
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <span>-</span>
                        </div>
                        <input
                          size={String(numberOfItems).length || 1}
                          className="qtyControlsInput"
                          type="text"
                          value={numberOfItems}
                          onChange={e => {
                            handleSetQuantityInput(e);
                          }}
                          onBlur={handleOnInputBlur}
                        />
                        <div
                          className="qtyControlsPlus no-select"
                          onClick={() => setNumberOfItems(numberOfItems + 1)}
                          style={{ cursor: "pointer" }}
                        >
                          <span>+</span>
                        </div>
                      </div>

                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>


              </div>

              <div className="row">
                <div className="col-xs-12">
                  <div id="buyBoxItemInfo2">
                    <GrandTotalCheckBoxItems price={price} />
                  </div>
                  <div id="buyBoxItemInfo3"></div>
                </div>
              </div>

              {/* <div className="row">
                <div className="col-xs-12 stockPage">
                  {!inStock.initial &&
                    !inStock.instock &&
                    !inStock.discontinued && (
                      <React.Fragment>
                        <div className="add-to-cart-stock-status">
                            This item may have limited inventory and we recommend
                          that you chat with the seller to confirm the inventory
                          available before Adding this item to the Cart.
                          Sorry, this item is currently out of stock.
                        </div>
                        <NotifyOnReStock
                      supplier={{
                        stock: priceState.invs[0].instock,
                        code: priceState.code,
                        itemid: priceState.itemid
                      }}
                      renderedInsideAddToCartBox={true}
                    />
                      </React.Fragment>
                    )}
                  {props.productUnavailable === false ? (
                    <React.Fragment>
                      <div
                        id="buyBoxAddToCartBtn"
                        className={
                          inStock.instock  || inStock.discontinued === false 
                            ? ""
                            : "out-of-stock"
                        }
                      >
                        <div
                          className={`addToCartBtn${shouldWiggle ? " wiggle" : ""
                            }`}
                          title={translate("js.item.addtocart")}
                          onClick={() => handleAddToCart()}
                        >
                          <div>
                            <span>{translate("js.item.addtocart")} </span>
                          </div>
                          <div>
                            <i className="material-icons add-icon">
                              add_shopping_cart
                            </i>
                          </div>
                        </div>
                        {requestingAddToCartState ? (
                          <Loading className="addToCartLoading" />
                        ) : null}
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true
                            }}
                            onClose={handleTooltipClose}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="We value your privacy and security.  Our security system encrypts your information during transmission.  Your credit card details  are sent do a payment processor and are not shared with this site or any third party sellers."
                          >
                            <div
                              className="Securetransaction"
                              onClick={handleTooltipOpen}
                            >
                              <i className="material-icons">lock_outline</i>{" "}
                              Secure transaction
                            </div>
                          </Tooltip>
                        </ClickAwayListener>
                      </div>

                      {!inStock.instock &&
                        inStock.discontinued &&
                        !inStock.nextshipdate ? (
                        <p>
                          Sorry, this item is out of season or no longer
                          available.
                        </p>
                      ) : null}
                      {!inStock.instock &&
                        inStock.discontinued &&
                        inStock.nextshipdate ? (
                        <p>
                          Sorry, this item is out of season or no longer
                          available. We expect to have more on{" "}
                          {inStock.nextshipdate}.
                        </p>
                      ) : null}
                      {props.storeInfo && (
                        <ChatTheSeller
                          storeSellerData={props.storeInfo.storeSellerData}
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <p style={{ margin: "20px 0" }}>
                      SORRY, THIS PRODUCT IS CURRENTLY UNAVAILABLE
                    </p>
                  )}

                  <div
                    id="buyBoxQuoteBtn"
                    className={`add-to-order${inStock ? "" : " active"}`}
                  >
                    <div
                      className="addToCartBtn"
                      title="Add to Order"
                      onClick={() => handleAddToCart(true)}
                    >
                      <div>
                        <span>Add to Order</span>
                      </div>
                      <div>
                        <i className="material-icons add-icon">description</i>
                      </div>
                    </div>
                  </div>
                  <div id="buyBoxEnquiryBtn">
                    <div
                      id="enquiry-204"
                      className="addToCartBtn sendEnquiryBtn"
                      title={translate("js.item.enquiry")}
                      onClick={handleEnquiryModalOpenClicked}
                    >
                      <div>{translate("js.item.enquiry")}</div>
                      <div>
                        <span>
                          <i className="material-icons add-icon">mail_outline</i>
                        </span>
                      </div>
                    </div>
                  </div>

                  {isMobileState && (
                    <WishListBarMobile
                      data={props.data}
                      productUnavailable={props.productUnavailable}
                      price={price}
                    />
                  )}
                </div>
              </div> */}
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddToCartBox;
