// extracted by mini-css-extract-plugin
export var container = "EnquiryModal-module--container--fS752";
export var wrapper = "EnquiryModal-module--wrapper--T3xdP";
export var content = "EnquiryModal-module--content--2f5gx";
export var actionsWrapper = "EnquiryModal-module--actionsWrapper--xbABi";
export var actions = "EnquiryModal-module--actions--I3ePn";
export var title = "EnquiryModal-module--title--dI+Z-";
export var loginTitle = "EnquiryModal-module--loginTitle--r3Qix";
export var backToFileUploadBtn = "EnquiryModal-module--backToFileUploadBtn--6kwGE";
export var skipFileUploadBtn = "EnquiryModal-module--skipFileUploadBtn--icwRP";
export var loginContent = "EnquiryModal-module--loginContent--Yd+tI";
export var sendMessageBtn = "EnquiryModal-module--sendMessageBtn--jl-LO";
export var loginBtn = "EnquiryModal-module--loginBtn--8-ZDT";
export var contentTitle = "EnquiryModal-module--contentTitle--iFo58";
export var messageInput = "EnquiryModal-module--messageInput--1nkTA";
export var messageInputWrapper = "EnquiryModal-module--messageInputWrapper--uxw+v";
export var messageSendSuccess = "EnquiryModal-module--messageSendSuccess--ZnzI2";
export var messageSendFailure = "EnquiryModal-module--messageSendFailure--Ve+GR";
export var fileInput = "EnquiryModal-module--fileInput--maojt";