import React, { useContext, useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { PREVIEW } from "../../../../project-config.js";
import { Link } from "gatsby";
import { navigate } from "@reach/router";

import {
  fetchCategoryFromRender,
  changeCategoryName,
  backToCategory
} from "../../../../redux/actions/categoryActions";

import { I18nContext } from "../../../../i18n/index";

const Breadcrumb = ({ back }) => {
  const dispatch = useDispatch();
  const { langCode, translate } = useContext(I18nContext);
  const [breadcrumb, setBreadcrumb] = useState([]);

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  const categoryParentsNameState = useSelector(
    state => state.categoryReducer.parents,
    shallowEqual
  );

  const breadcrumbState = useSelector(
    state => state.mainReducer.breadcrumb,
    shallowEqual
  );

  useEffect(() => {
    if (breadcrumbState && breadcrumbState.length > 1) {
      setBreadcrumb(
        breadcrumbState.filter(
          bread => bread.name !== "Home" && bread.name !== "Shop"
        )
      );
    }
  }, [breadcrumbState]);

  const handleBreadCrumbClicked = (cid, cat, parents) => {
    let category = navCatsState;
    for (let parent of parents) {
      category = category.childs.filter(c => {
        if (c.cid === parent[1]) {
          /*parent's cid equals cid */
          return true;
        }
      })[0];
    }

    dispatch(changeCategoryName(cat, parents));
    dispatch(fetchCategoryFromRender(cid, cat, parents, "", category));
  };

  return (
    <div className="sub-nav-bread">
        {back && (
        <button
          className="backBtn"
          onClick={() => {
            dispatch(backToCategory());
            navigate(-1);
          }}
        >
          <span className="material-icons">keyboard_arrow_left</span> Back
        </button>
      )}
    </div>
  );
};

export default Breadcrumb;
