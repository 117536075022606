import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ReactTooltip from 'react-tooltip';
import VendorList from "./VendorList";
import { I18nContext } from "../../../../i18n/index";


import "./Styles/VendorList.css";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor:"#3F57C6",
    paddingRight:"0px !important"
  },
  title: {
    // marginLeft: theme.spacing(2),
    flex: 1,
    textAlign:"left",
    fontSize:"20px",
    paddingLeft:"15px",
    whiteSpace:"nowrap",
    overflow:"hidden",
    textOverflow:"ellipsis"
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VendorListModal = ({ handleEnquiryModalOpenClicked }) => {

  const { translate, currency, priceConvert } = React.useContext(I18nContext);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="vendorModalWrapper">
      <button className="vendorModal" onClick={handleClickOpen}>
        <span data-background-color="#3F57C6" data-tip="You can see all available sellers here" data-class="datatool">View All Sellers</span>
      </button>
      {/* <div className="vendormain" onClick={handleClickOpen}><img src="https://ik.imagekit.io/ofb/dbz/moresellers_1__ZNJZiyNHTHS.jpg" /></div> */}
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {translate("js.item.alsoavailable")}  
            </Typography>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <VendorList 
          handleEnquiryModalOpenClicked={handleEnquiryModalOpenClicked}
        />
      </Dialog>
    </div>
  );
};

export default VendorListModal;
