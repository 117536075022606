import React, { useEffect, useState, useRef, useLayoutEffect } from "react";

import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { I18nContext } from "../../../../i18n/index";

import * as classes from "./style/SupplierInfo.module.css";


const SupplierInfo = ({ storeInfo }) => {
  const { translate } = React.useContext(I18nContext);

  const supplierInfo = useSelector(
    state => state.productReducer.supplierInfo,
    shallowEqual
  );

  const [supplierInfoData, setSupplierInfoData] = React.useState(null);

  useEffect(() => {
    if (supplierInfo && supplierInfo.length > 0) {
      setSupplierInfoData(supplierInfo[0]);
    }
  }, [supplierInfo]);

  console.log("supplierInfoData", supplierInfoData, storeInfo);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const handleLogin = () => {
    document.getElementById("login-icon-btn").click();
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
       

        <div className={classes.supplier_wrapper}>
          <div className={classes.supplier_inner_1}>
            <div className={classes.sup_logo}>
              <img src={`https://ik.imagekit.io/ofb/dbz/storeimages/Metanik_Palm_Oil_Mill.png`} style={{ maxWidth: "100%" }} />
            </div>
            <div className={classes.sup_add}>
              <h4>{storeInfo?.storeSellerData?.company_name}</h4>
              <p>{storeInfo?.storeSellerData?.location}</p>
              <p>
                {storeInfo?.storeSellerData?.country}, Pincode -{" "}
                {storeInfo?.storeSellerData?.postal_code}
              </p>              
              <div className={classes.storin}>
                  <a href={`tel:${storeInfo?.storeSellerData?.phone_number}`} title="Phone Number">
                    <i className="material-icons">phone</i>
                  </a>
                  <a href={`sms:${storeInfo?.storeSellerData?.phone_number}`} title="Message">
                    <i className="material-icons">sms</i>
                  </a>
                
                  <a target="_blank" href={storeInfo?.storeSellerData?.facebook} title="Email">
                    <i className="material-icons">local_post_office</i>
                  </a>
              </div>
            </div>
          </div>
          {/* <div className={classes.supplier_inner_2}>
            <div className={classes.sup_contact}>
              <a href="#">View more details</a>
            </div>
            <div className={classes.sup_icons}>
              {loginNameState == "" ? (
                <React.Fragment>
                  <button onClick={handleLogin}>
                    <i class="material-icons">phone</i>
                  </button>
                  <button onClick={handleLogin}>
                    <i class="material-icons">sms</i>
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <a href={`tel:${storeInfo.storeSellerData?.phone_number}`}>
                    <i class="material-icons">phone</i>
                  </a>
                  <a href={`sms:${storeInfo.storeSellerData?.phone_number}`}>
                    <i class="material-icons">sms</i>
                  </a>
                </React.Fragment>
              )}
              <a target="_blank" href={storeInfo.storeSellerData?.facebook}>
                <i class="material-icons">local_post_office</i>
              </a>
            </div>
          </div> */}
          {/* <div className={classes.more_details}>
            <a href="#">View more details</a>
          </div> */}
        </div>

        {/* {
                    supplierInfoData &&
                    supplierInfoData.distributorOrder.length > 0 &&
                    supplierInfoData.distributorOrder.map((supplierData) => {
                        console.log("supplierInfoData11", supplierData)
                        Object.keys(supplierData).map((props, index) => {
                            if (props) {
                                console.log("supplierInfoData22", supplierData[props])
                                return (
                                    <div key={props} className={classes.row}>
                                        <div className={classes.prop}>{props}</div>
                                        <div className={classes.value}>{supplierData[props]}</div>
                                    </div>
                                )
                            }
                        })
                    })
                } */}
      </div>
    </div>
  );
};

export default SupplierInfo;
