import React, { useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { fetchWishList } from "../../../../redux/actions/wishListActions";
import { toggleWishListAction } from "../../../../redux/actions/wishListActions.js";
import * as classes from "./Styles/BDWishListBar.module.css";
import { I18nContext } from "../../../../i18n/index";
import handleDeleteProductFromWishlist from "../../../AC-WishList/components/handleDeleteProductFromWishList";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { VID, PROJECT_LINK } from "../../../../project-config.js";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const WishListBar = ({ data, productUnavailable, price: pPrice, itemid }) => {
  const dispatch = useDispatch();
  const { translate } = React.useContext(I18nContext);

  const [favouriteState, setFavouriteState] = useState("favorite_border");

  const wishListState = useSelector(
    state => state.wishListReducer.wishlist,
    shallowEqual
  );

  const handleLogin = () => {
    document.getElementById("login-icon-btn").click();
  };

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );


  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const productState = useSelector(
    state => state.productReducer.product,
    shallowEqual
  );

  const productLinkState = useSelector(
    state => state.productReducer.productInitial.productLink,
    shallowEqual
  );

  const itemDetail = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const priceDetail = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  const wishlistInfo = useSelector(
    state => state.wishListReducer.wishlist,
    shallowEqual
  );

  console.log("wishlistInfo", wishlistInfo);

  let isItemWishlisted =
    wishListState.length > 0
      ? wishListState.some(
        w =>
          w.id == (itemDetail.itemId || data?.productData?._xResult[0]?.itemId)
      )
      : false;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const addItemToWishList = itemid => {
    let form = new FormData();
    form.append("itemid", itemid);
    form.append("action", "add");
    fetch(
      `${PROJECT_LINK}/wishlistservice.ajx?vid=${VID}`,
      {
        method: "POST",
        body: form,
        headers: {
          Accept: "*/*",
          credentials: "same-origin"
        },
        mimeType: "multipart/form-data",
        data: form
      }
    )
      .then(res => {
        dispatch(fetchWishList());
        setTimeout(() => setOpen(true), 1000);
        setTimeout(() => setOpen(false), 4000);
      })
      .catch(err => {
        console.error("WishList", err.message);
      });

    isItemWishlisted
      ? setFavouriteState("favourite_border")
      : setFavouriteState("favourite");
    //toggleWish(itemid);
  };

  // const handleToggleWishlistIcon = e => {

  // };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert onClose={handleClose} severity="success">
          Item Added to Wishlist
        </Alert>
      </Snackbar>
      {loginNameState == "" ? (
        <div id="buyBoxAddToCartBtn"
          style={{
            paddingLeft: "0px",
            cursor: "pointer"
          }}>
          <button
            className="addToCartBtn"
            onClick={handleLogin}
            style={{
              background: "#fff",
              border: "1px solid #3f57c6",
              color: "#3f57c6"
            }}
          >
            <div>
              <span>
                {
                  wishListState.filter(
                    wishList =>
                      wishList?.item?.itemId === data?.productData?._xResult[0]?.itemId
                  ).length > 0 ? (
                    <>Remove Wishlist</>
                  ) : (
                    <>Add to Wishlist</>
                  )
                }
              </span>
            </div>
            <div id="buyBoxWishlist" >
              <span
                className="wishlistHeart"
                title={translate("js.item.wishlist.add")}
              >
                <span style={{ cursor: "pointer" }}>

                  <i className="material-icons add-icon" style={
                    wishListState.filter(
                      wishList =>
                        wishList?.item?.itemId === data?.productData?._xResult[0]?.itemId
                    ).length > 0 || favouriteState === "favorite"
                      ? { color: "#3F57C6" }
                      : null
                  } >
                    {wishListState.filter(
                      wishList =>
                        wishList?.item?.itemId === data?.productData?._xResult[0]?.itemId
                    ).length > 0 || favouriteState === "favorite"
                      ? `favorite`
                      : `favorite_border`}
                  </i>
                </span>
              </span>
            </div>
          </button>
        </div>
      ) : (
        <div id="buyBoxAddToCartBtn"
          style={{
            paddingLeft: "0px",
            cursor: "pointer"
          }}>
          <button
            className="addToCartBtn"
            style={{
              background: "#fff",
              border: "1px solid #3f57c6",
              color: "#3f57c6"
            }}
            onMouseEnter={() => setFavouriteState("favorite")}
            onMouseLeave={() => setFavouriteState("favorite_border")}
            onClick={itemid => {
              //handleToggleWishlistIcon(e);
              wishListState.filter(
                wishList =>
                  wishList?.item?.itemId === data?.productData?._xResult[0]?.itemId
              ).length > 0
                ? handleDeleteProductFromWishlist(
                  data.productData._xResult[0].itemId,
                  dispatch
                )
                : addItemToWishList(data.productData._xResult[0].itemId);
            }}

          >
            <div>
              <span>{!isMobileState ? (
                wishListState.filter(
                  wishList =>
                    wishList?.item?.itemId === data?.productData?._xResult[0]?.itemId
                ).length > 0 ? (
                  <>Remove Wishlist</>
                ) : (
                  <>Add to Wishlist</>
                )
              ) : (
                wishListState.filter(
                  wishList =>
                    wishList?.item?.itemId === data?.productData?._xResult[0]?.itemId
                ).length > 0 ? (
                  <>Remove Wishlist</>
                ) : (
                  <>Add to Wishlist</>
                )
              )}</span>
            </div>
            <div id="buyBoxWishlist" >
              <span
                className="wishlistHeart"
                title={translate("js.item.wishlist.add")}
              >
                <span style={{ cursor: "pointer" }}>

                  <i className="material-icons add-icon" style={
                    wishListState.filter(
                      wishList =>
                        wishList?.item?.itemId === data?.productData?._xResult[0]?.itemId
                    ).length > 0 || favouriteState === "favorite"
                      ? { color: "#3F57C6" }
                      : null
                  } >
                    {wishListState.filter(
                      wishList =>
                        wishList?.item?.itemId === data?.productData?._xResult[0]?.itemId
                    ).length > 0 || favouriteState === "favorite"
                      ? `favorite`
                      : `favorite_border`}
                  </i>
                </span>
              </span>
            </div>
          </button>
        </div>

      )}
    </>
  );
};

export default WishListBar;

