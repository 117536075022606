import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import htmldecoder from "../../../../functions/htmldecoder";

import { I18nContext } from "../../../../i18n/index";
import "./Styles/OtherInfoTab.css";



const OtherInfoTab = ({ longDesc, properties }) => {
  const { translate } = React.useContext(I18nContext);




  return (
    <div
      className="product-details-specs-container"
    >



      <div id="description" className="otherDesktop">
        {longDesc && Object.keys(longDesc).map((desc, index) => {
          if (longDesc[desc] !== "") {
            return (
              <p
                key={index}
                dangerouslySetInnerHTML={{
                  __html: htmldecoder(longDesc[desc])
                }}
              />
            );
          }
        })}
      </div>



      {properties && properties.length > 0 ? (
        <div className="specs-list">
          <table className="table table-striped aboutprodutcinfo">

            <tbody>
              {properties
                ? properties
                  .slice(0, properties.length / 2)

                  .map(property => {
                    return (
                      <tr key={property.propname} className={`www prop_${property.propname.split("_").join(" ")}`}>
                        <th>
                          {property.propname.split("_").join(" ")}
                        </th>

                        {property.propname == "Certifications_URL" ?
                          <>
                            <td>
                              <a title={property.propvalue} target="_blank" href={property.propvalue}>
                                {property.propvalue}
                              </a>
                            </td>
                          </> :

                          <td title={property.propvalue}>
                            {property.propvalue}
                          </td>
                        }

                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
                  

          <h1 className="pal-head">Packaging and Delivery</h1>

          <table className="table table-striped Packaging">
            <tbody>
              {properties
                ? properties
                  .slice(0, properties.length / 2)

                  .map(property => {
                    return (
                      <tr key={property.propname} className={`pack_${property.propname.split("_").join(" ")}`}>
                        <th>
                          {property.propname.split("_").join(" ")}
                        </th>
                        <td title={property.propvalue}>{property.propvalue}</td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>

          <table className="table table-striped Packaging">
            <tbody>
              {properties
                ? properties
                  .slice(properties.length / 2, properties.length)
                  .map(property => {
                    return (
                      <tr key={property.propname} className={`pack_${property.propname.split("_").join(" ")}`}>
                        <th>
                          {property.propname.split("_").join(" ")}
                        </th>
                        <td title={property.propvalue}>{property.propvalue}</td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>

        </div>
      ) : (
        <h3>There is no {translate("js.item.specifications")} to show</h3>
      )}



    </div>
  );
};

export default OtherInfoTab;
